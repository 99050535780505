.GoodsList {
    background-color: @white;

    &-head,
    &-row {
        display: grid;
        align-items: center;
    }

    &-head {
        padding: 2px 48px;
        margin: 0 -24px;

        .GoodsList-item {
            font-family: 'Rubik', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: rgba(@black,.5);

            &Title {
                padding-left: 30px;
            }

            &Count {
                padding-right: 5px;
            }
        }
    }

    &-row {
        padding: 10px 24px;
        position: relative;
        border-bottom: 1px solid rgba(@black-0,.1);
    }

    &:last-child {
        .GoodsList-row:last-child {
            border-bottom: 0;
        }
    }

    &-item {
        padding: 10px 0;

        &Title {
            display: flex;
        }

        &Title,
        &HeadCode {
            padding-right: 5px;
        }
    }
}

.GoodsListLeftoverHeaderRectangle {
    height: 1px;
    width: 100%;
    background-color: #DEDFE1;;
}

@media screen and (max-width: 991px) {
    .GoodsList {
        padding: 0 14px;

        &-head {
            padding: 2px 14px;
            margin: 0 -14px;
        }

        &-item {
            &HeadCode,
            &Count {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .GoodsList {
        &-head,
        &-row {
            grid-template-rows: repeat(1, auto);
            grid-template-columns: minmax(30px, 1fr) minmax(200px, 11.67fr) minmax(90px, 1fr) minmax(120px, 1fr);
        }

        &-item {
            &Title {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
    }
}

