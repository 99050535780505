.MessageModalOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vw;
  z-index: 100;
  background: rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(8px);
}

.MessageModalComponentWrapper {
  background-color: #fff;
  position: inherit;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  border: solid 1px #e6e6e6;
  max-height: calc(100vh - 250px);
  width: 864px;
  overflow-y: auto;
}

.MessageModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #EEEEEF;
}

.MessageModalTitle {
  padding: 30px 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #27293A;
}

.MessageModalCloseWrapper {
  padding-top: 14px;
  padding-right: 24px;
}

.MessageModalContentBlock {
  margin: 21px 40px 0 40px;
  padding-bottom: 10px;
  //border-bottom: 1px solid #EEEEEF;
  display: flex;
  flex-direction: row;
}

.MessageModalRectangle {
  height: 1px;
  background-color: #EEEEEF;
}

.MessageModalRectangleWithPadding {
  height: 1px;
  background-color: #EEEEEF;
  margin: 0 40px;
}

.MessageModalContentBlockTitle {
  padding-right: 15px;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-transform: uppercase;
  color: #757681;
}

.MessageModalContentBlockData {
  width: 100%;
}

.MessageModalContentInputWrapper {
  font-size: 14px;
  line-height: 20px;
  color: #27293A;
  margin-top: -20px;
  width: 100%;
  max-height: 250px;
}

.MessageModalInputTo, .MessageModalInputTheme {
  max-height: 100px !important;
}

.HiddenOverflowContent {
  overflow-y: auto;
}

.MessageModalContentEditWrapper {
  font-size: 14px;
  line-height: 20px;
  color: #27293A;
  max-height: 300px;
  overflow-y: auto;
}

.MessageModalContentInputWrapper .InputWrapper textarea {
  resize: none !important;
}

.MessageModalContentInputWrapper .InputWrapper textarea::placeholder {
  padding-top: 4px;
}

.MessageModalTextInputArea {
  margin-left: -14px;
}

.MessageModalTextInputArea .InputWrapper {
  min-height: 270px;
  width: 100%;
}

.MessageModalTextInputArea .InputWrapper textarea {
  width: 100%;
  height: 100%;
}

.MessageModalTextInputArea::placeholder {
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-transform: uppercase;
  color: #757681;
}

.MessageModalFooter {
  padding: 16px 0;
  border-top: 1px solid #EEEEEF;
  display: flex;
  flex-direction: row;
}

.MessageModalFooterSendButton {
  height: 40px;
  align-items: center;
  padding-top: 8px !important;
}

.MessageModalFooterSendButton {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #FFFFFF;
}

.MessageModalFooterSendButtonDate {
  padding-left: 8px;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px;
  opacity: 0.9;
}

.ScheduledDatePickerWrapper {
  width: 344px;
}

.CalendarHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 30px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #27293A;
}

.CalendarHeaderTitleWrapper:first-letter {
  text-transform: uppercase;
}

.MessageUploadFileListWrapper {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  margin: 16px 24px;
  max-height: 106px !important;
  overflow-y: auto;
}

.MessageSendAfterPickerWrapper div div div ul .ant-picker-ok button {
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 4px 0;
}
.MessageSendAfterPickerWrapper div div div ul .ant-picker-now {
  display: none;
}

.DatePickerExtraFooter {
  position: absolute;
  padding: 0 !important;
  display: flex;
  flex-direction: row;
}

.DatePickerExtraFooterButtons button {
  height: 40px;
  width: 124px;
  padding: 0 !important;
  margin-top: 8px;
}

.UploadItemRenderMessageModal {
  background: #F5F6F8 !important;
  border-radius: 2px;
}
.UploadDownloadLink a {
  color: #27293A !important;
  text-decoration: none !important;
}

.UploadDownloadLink:hover {
  opacity: 0.8;
  cursor: pointer;
}

.UploadItemDownload {
  visibility: hidden;
  padding-top: 6px;
}

.UploadItemWithDownloadRender:hover {
  .UploadItemDownload {
    visibility: inherit !important;
  }
}

.MessageModalTooltipWrapper {
  position: absolute;
  background: #757681;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  padding: 5px 10px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  margin-top: -32px;
  transform: translateX(calc(-50% + 20px));
}

.MessageModalTooltipDelete, .MessageModalTooltipUpload, .MessageModalTooltipDate {
  visibility: hidden;
}

.MessageModalDelete:hover .MessageModalTooltipDelete:not(:hover) {
  visibility: visible !important;
}

.MessageModalUpload:hover .MessageModalTooltipUpload:not(:hover) {
  visibility: visible !important;
}

.MessageModalDate:hover .MessageModalTooltipDate:not(:hover) {
  visibility: visible !important;
}

.MessageDeleteModalWrapper {
  background-color: #fff;
  position: inherit;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  border: solid 1px #e6e6e6;
  max-height: calc(100vh - 250px);
  padding: 32px;
  max-width: 450px;
}

.MessageDeleteModalTitle {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 32px;
  width: 100%;
  text-align: center;
  color: #27293A;
}

.MessageDeleteModalButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SelectClubsWrapper {
  width: 100%;
  display: block;
}

.SelectClubsWrapper .ant-select-selector {
  border: 0 !important;
  overflow-y: auto;
  max-height: 100px;
}
