.MessagePreviewBackWrapper {
  font-size: 16px;
  line-height: 19px;
  color: #757681 !important;
  cursor: pointer;
}

.MessagePreviewBackText {
  padding-left: 10px;
  padding-top: 2px;
}

.MessagePreviewContentWrapper {
  background-color: #fff;
  margin-top: 24px;
  padding: 40px;
}

.MessageContentTitle {
  font-size: 24px;
  line-height: 28px;
  color: #27293A;
}

.MessageContentDate {
  padding-left: 20px;
  padding-top: 8px;
  font-size: 14px;
  line-height: 17px;
  color: #757681;
}

.MessageContentBody {
  padding: 30px 0 24px 0;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #27293A;
}

.MessageContentPart {
  padding-top: 8px;
}

.MessageFilesRectangle {
  width: 100%;
  max-width: 864px;
  height: 1px;
  background: #EEEEEF;
  margin-bottom: 22px;
}
.MessageFilesInfo {
  font-size: 12px;
  line-height: 20px;
}

.MessageFilesInfoCount {
  color: #525461;
  padding-left: 8px;
}

.MessageFilesInfoAll {
  color: #2D8AF1;
  padding-left: 16px;
}

.MessageFilesInfoAllHover:hover {
  cursor: pointer;
  opacity: 0.8;
}

.MessageFileItemsWrapper {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  max-height: 106px !important;
  overflow-y: auto;
}

.MessageUploadItemRender {
  background-color: #F5F6F8;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 4px 12px;
  margin: 8px 8px 8px 0;
  vertical-align: center;
}

.MessageUploadItemRender:hover {
  .UploadItemDownload {
    visibility: inherit;
  }
}
