@black-0: rgb(0,0,0);
@black: rgb(39,41,58);
@black-2: rgb(82,84,97);
@black-3: #757681;
@white: #fff;
@grey-bg: #F5F6F8;
@grey-2: #DEDFE1;
@grey-3: #EEEEEF;
@action-info: #2D8AF1;
@action-danger: #FF7454;
@action-warning: #FFA132;
@light-primary: rgb(222,245,235);
@light-info: #E0EDFD;
@light-warning: #FFF1E0;
@light-danger: #FFEAE5;
@main: #25BA7C;
@main-dark: #268664;
@main-light: #B9E9D5;
@main-lighter: @light-primary;

@primary: @main;
@secondary: @grey-3;
@border-radius: 2px;
@height-base: 56px;
@height-lg: 64px;
@height-sm: 48px;

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 375px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  color: @black !important;
}

h1,
.Title-h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 0;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

img {
  display: block;
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(@white);
  border-radius: 2px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: rgba(@white);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(@black-3, 0.3);
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background-color: rgba(@white);
}

.h-100 {
  height: 100%;
}

.ant-col {
  display: flex !important; // fix style Antd!
}

.ant-row {
  flex: 1 1 0;
}

.ant-layout {
  min-height: 100%;
}

.ant-tooltip * {
  color: #0d0d0d !important;
}

.DisplayRow {
  display: flex;
  flex-direction: row;
}

.StatusRound {
  width: 6px;
  height: 6px;
  margin-top: 8px;
  margin-right: 10px;
  border-radius: 50%;
}

.RelativePosition {
  position: relative;
}

.DisplayNone {
  display: none;
}