.WidthAll {
  width: 100%;
}

.MessageContentHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.MessageContentLeft {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.MessageContentRight {
  margin-left: auto;
}

@media screen and (max-width: 991px) {
  .MessageContentRight {
    margin-left: auto;
  }
}

.MessageContentWrapper {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: -21px;
}

.MessageItemWrapper {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1px;
}

.MessageItemWrapper:hover {
  cursor: pointer;
  background: #F4FCF8;
}

.GridPlaceCenter {
  display: grid;
  place-items: center;
}

.MessageItemIsReadWrapper {
  height: 56px;
  min-width: 40px;
}

.MessageItemTitleWrapper {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #27293A;
  padding-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.MessageItemTitleUnread {
  font-weight: 500 !important;
}

.MessageItemContentWrapper {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #757681;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -10px;
  padding: 0 120px 20px 42px;
}

.MessageItemIsRead {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #25BA7C;
}

.MessageItemFileWrapper {
  margin-left: auto;
  padding: 0 24px;
}

.MessageItemDateMonthWrapper {
  padding-right: 24px;
  text-transform: capitalize;
}

.MessageItemDateMonthTimeWrapper {
  padding-left: 4px;
}

.MessageItemDateMonthWrapper, .MessageItemDateWrapper {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 16px;
  color: #757681;
  opacity: 0.75;
}

.FlexRow {
  display: flex;
  flex-direction: row;
}

.MessageContentPaginationWrapper {
  padding-top: 24px;
  width: 100%;
  display: grid;
  place-items: center;
}
