.AccessPriceWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  height: fit-content;
}

.AccessPriceItemWrapper {
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  border: 2px solid transparent;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px rgba(39, 41, 58, 0.08);
  border-radius: 2px;
  width: 412px;
  height: 100px;
}

.AccessPriceItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  width: 100%;
  max-width: 100%;
}

.AccessPriceItemLabel {
  line-height: 20px;
  color: #27293A;
  max-width: 300px;
  max-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  line-height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  min-height: 40px;
}

.AccessPriceItemIconWrapper {
  padding: 16px 0 7px 0;
}

.AccessPriceItemWrapper:hover {
  cursor: pointer;
  border: 2px solid #B9E9D5;
  box-shadow: 0px 0px 8px rgba(37, 186, 124, 0.2);
}

.AccessPriceItemActiveWrapper {
  border: 2px solid #25BA7C !important;
}

.AccessPriceItemActiveWrapper:hover {
  cursor: default !important;
  box-shadow: none !important;
}
