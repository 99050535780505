.Pagination {
  &Wrapper {
    display: flex;

    .Pagination {
      margin: 0 auto;
    }
  }

  ul {
    li {
      a {
        color: @black-3;
        font-size: 12px;
      }

      &:first-child button {
        border-left: solid 1px @grey-2;
        border-bottom-left-radius: 4px !important;
        border-top-left-radius: 4px !important;
        border-bottom: solid 1px @grey-2;
        border-top: solid 1px @grey-2;
      }

      &:last-child button {
        border-right: solid 1px @grey-2;
        border-bottom-right-radius: 4px !important;
        border-top-right-radius: 4px !important;
        border-bottom: solid 1px @grey-2;
        border-top: solid 1px @grey-2;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      button {
        border-radius: 0 !important;
        color: @black-3;
      }
    }
  }

  .ant-pagination {
    &-item,
    &-prev,
    &-next,
    &-jump-next,
    &-jump-prev,
    &-item-container {
      margin: 0;
      border-radius: 0;
      height: 36px;
      line-height: 36px;
      min-width: 36px;
    }

    &-item,
    &-next {
      margin-left: -1px;
      border-color: @grey-2;
    }

    &-item {
      &:focus,
      &-active {
        border-color: @grey-2;

        a {
          &,
          a:hover {
            color: @black;
          }
        }
      }

      &-active {
        background-color: @main-lighter;
        color: @black;
      }

      &:not(.ant-pagination-item-active):hover {
        background-color: @grey-3;
        border-color: @grey-2;
      }

      &-link .anticon {
        vertical-align: 0;
      }
    }

    &-jump {
      &-next,
      &-prev {
        .ant-pagination-item-container {
          border-right: solid 1px @grey-2;
          border-bottom: solid 1px @grey-2;
          border-top: solid 1px @grey-2;
          background-color: @white;

          &:hover,
          &:focus {
            background-color: @grey-3;
          }
        }
      }
    }

    &-prev,
    &-next {
      &:hover,
      &:focus {
        .ant-pagination-item-link {
          border-color: @grey-2
        }
      }

      &:not(.ant-pagination-disabled) {
        &:focus,
        &:hover {
          .ant-pagination-item-link {
            background-color: @grey-3;
          }
        }
      }
    }

    &-disabled .ant-pagination-item-link {
      background-color: @grey-3;
      color: @grey-2;
    }
  }
}

