.Header {
  display: flex;
  align-items: center;
  z-index: 100;

  &-main {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 112px;

    .ButtonPrimaryWrapper {
      margin: auto 0 auto auto;
    }
  }

  &-inner {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: @white;
    box-shadow: 0 2px 4px rgba(@black-0, 0.2);
  }
}

.Navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 0;

  &-list {
    display: flex;
  }

  &-item_disabled {
    cursor: default !important;
    color: #ADB5BD !important;

    svg path {
      fill: #ADB5BD !important;
    }

    &:hover {
      color: #ADB5BD !important;

      svg {
        fill: #ADB5BD !important;
      }
    }
  }

  &-item {
    box-sizing: border-box;
    min-width: 65px;
    padding: 4px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 10px;
    line-height: 1.25;
    font-size: 12px;
    color: #525461;
    text-decoration: none;
    border-radius: @border-radius;
    cursor: pointer;
    position: relative;

    svg {
      height: 24px;
      margin-bottom: 5px;
      fill: #525461;
    }

    &:hover {
      color: @main;

      svg {
        fill: @main;

        path {
          fill: @main;
        }
      }
    }

    &.IconFillPath:hover {
      path {
        fill: @main;
      }
    }

    &_admin {
      svg {
        fill: none;

        path {
          fill: none;
        }
      }
      &:hover {
        svg {
          fill: none !important;

          path {
            fill: none !important;
            stroke: @main;
          }
        }
      }
    }

    &.active {
      color: @main-dark;
      background: @light-primary;

      svg {
        fill: @main;
      }
    }

    &.IconFillPath_active {
      path {
        fill: @main;
      }
    }
  }
}

.Footer {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;

  &-row {
    display: flex;
    flex: 1 1 0;
  }
}

.BetIcon {
  padding-top: 2px;
  padding-right: 48px;
}

.LogOut {
  margin-left: auto;
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
}

.LogOutIcon {
  cursor: pointer;
}

.Profile {
  color: @white;
  padding-top: 6px;
  padding-right: 24px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.83px;
  text-decoration: none;
}

.UserWrapper {
  padding-right: 16px;
  padding-top: 4px;
  color: @white;
}

.Container {
  max-width: 1276px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: 1 1 0;
}

.Logo {
  display: flex;
  align-items: center;

  &-header svg {
    height: 40px;
    cursor: pointer;
  }

  &-footer {
    justify-content: center;
    flex: 1 1 0;

    svg {
      height: 34px;
    }
  }
}

.Copyright {
  display: flex;
  align-items: center;
  flex: 1 1 0;
  color: @black-3;
}

.Contacts {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 0;

  &-info {
    display: inline-flex;
    align-items: center;
    margin-left: 36px;

    a {
      color: @black-2;

      &:hover {
        color: @black;
      }
    }
  }

  svg {
    height: 20px;
    margin-right: 8px;
    fill: @black-2;
  }
}

.Main {
  padding: 24px 45px 45px;
  min-height: 1400px;
}

@media screen and (max-width: 991px) {
  .Main {
    padding-left: 15px;
    padding-right: 15px;
  }

  .Header {
    padding: 0 15px;

    &-main {
      height: 85px;
    }

    &-inner {
      .Logo-header svg {
        height: 28px;
      }
    }
  }

  .Navbar-item {
    min-width: 50px;
    margin-left: 5px;
  }

  .Footer {
    padding-left: 15px;
    padding-right: 15px;

    &-copyright {
      order: 3;
    }

    &-logo {
      order: 1;
      margin-bottom: 20px;
    }

    &-contacts {
      order: 2;
      margin-bottom: 10px;
    }

    .Copyright {
      justify-content: center;
    }

    .Contacts {
      justify-content: center;
      flex-direction: column;
      align-items: center;

      &-info {
        margin: 0 10px 10px;
      }
    }
  }
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.ProfilePopover {
  &Option {
    padding: 12px 15px;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    :hover {
      background-color: #f5f6f8;
    }
  }

  &Link a {
    color: #0d0d0d !important;
  }

  &Logout {
    color: #ff7454 !important;
  }
}

@media screen and (max-width: 991px) {
  .Navbar-item svg {
    height: 18px;
  }

  .Footer {
    &-row {
      flex-direction: column;
    }
  }
}

@media screen and (min-width: 992px) {
  .Navbar {
    align-items: center;

    &-item {
      justify-content: center;
    }
  }

  .Footer {
    &-row {
      align-items: center;
      justify-content: space-between;
    }
  }
}

.ProfilePopoverOption {
  color: #0d0d0d;
}

.ProfilePopoverOption:hover {
  background-color: #f5f6f8;
}

.NotificationLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 17px !important;
  background-color: #ff7454;
  border-radius: 20px;
  pointer-events: none;

  div {
    position: static;

    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */
    background-color: #ff7454;
    display: flex;
    align-items: center;
    text-align: center;

    /* Colors / White / 100% */

    color: #ffffff;
  }
}

.BageMenu {
  margin-left: 34px;
  margin-bottom: 29px;
}

@media screen and (max-width: 1112px) {
  .Main {
    .ant-col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.NavbarPopoverContentWrapper {
  height: 8px;
}

.FooterCartContainer {
  width: 100%;
  height: 101px;
  background: white;
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2);
}

.FooterCartContainerButton {
  width: 291px;
  height: 48px;
}

.FooterCartContainerButtonInner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
    path {
      fill: white;
    }
  }

}

.FooterCartContainerButtonInner_MinActive {
  svg path {
      fill: @black-3 !important;
    }
}


.FooterCartText,
.FooterCartButtonMiddle,
.FooterCartButtonLast {
  font-family: Rubik, sans-serif;
  font-style: normal;
}

.FooterCartText {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: @black-3;
}

.FooterCartButtonMiddle {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.FooterCartButtonLast {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.Navbar-item_Cart-notifi {
  width: 8px;
  height: 8px;
  background-color: @action-danger;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 15px;
}


