.ContactsWrapper {
  flex: 1 1 0;

  &-header {
      margin: 0 0 24px 0;
  }

  &-desc {
    font-size: 16px;
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 32px;
  }

  a {
    color: @action-info;
  }

  &-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 24px 16px;
    background: @white;
    flex: 1 1 0;
  }

  &-icon {
    height: 148px;
    margin-bottom: 20px;
  }

  &-info {
    strong {
      font-weight: 500;
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
    }

    p {
      color: #525461;
      margin-bottom: 0;
    }

    ul {
      margin: 0;
      padding-left: 20px;
      display: inline-block;

      li {
        margin-top: 15px;
      }
    }

    a {
      display: block;
    }

    .highlight {
      font-size: 16px;
      font-weight: 500;
      color: #2D8AF1;
    }
  }

  &-phone {
    display: block;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #2D8AF1;
  }
}

@media screen and (min-width: 768px) {
  .ContactsWrapper {
    &-list {
      flex-direction: row;
    }

    &-item {
      padding: 33px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .ContactsWrapper {
    &-item {
      flex-direction: row;
    }

    &-icon {
      margin: 0 35px 0 0;
    }
  }
}

