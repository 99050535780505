.LeftoverHistoryWrapper {
  display: flex;
  flex-direction: column;
}

.LeftoverHistory {
  font-weight: bold;
  font-size: 32px;
  line-height: 160%;
  color: #27293A;
}
