.ModalWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vw;
  z-index: 100;
  background: rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(8px);
}

.ModalBody {
  background-color: #fff;
  position: inherit;
  top: 25%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  border-radius: 2px;
  border: solid 1px #e6e6e6;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.ModalWrapperSpinWrapper {
  position: inherit;
  top: 30%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  div .anticon-spin {
    font-size: 60pt !important;
  }
}

.ModalContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 48px 16px;
}

.ModalHeader {
  font-weight: bold;
  text-align: center;
  color: #0d0d0d;
  padding-bottom: 16px;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}

.CloseWrapper {
  display: flex;
  justify-content: space-between;
}

.CloseIcon {
  padding: 16px 16px 0 0;
  cursor: pointer;
}
