.InputWrapper .ant-input:focus,
.InputWrapper .ant-input-focused,
textarea.ant-input:focus,
.ant-input:focus {
  box-shadow: none;
  border-color: @main;
}

.InputMaskWrapper {
  height: 60px;
  width: 100%;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-sizing: border-box;
  padding: 16px;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.InputMaskWrapper::placeholder {
  color: rgba(39, 41, 58, 0.4) !important;
}

.InputMaskWrapper:focus {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid @main !important;
}



.ErrorInputWrapper {
  .ErrorText {
    font-size: 10px;
    color: @action-danger;
    margin-top: 2px;
  }

  .ant-input {
    border-color: @action-danger;
  }
}

.InputCount {
  width: 47px;
  border-right: none;
  border-left: none;
  border-radius: 0;
  text-align: center;
  height: 32px;
  padding: 0;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  background-color: @white;

  &:hover,
  &:focus {
    border-color: rgba(@black-0, .2);
  }

  &ButtonRight,
  &ButtonLeft {
    width: 30px;
    height: 32px;
    padding: 0;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    background-color: @white;
    color: @black;

    &:hover,
    &:focus,
    &:active {
      color: @white !important;
      border-color: rgba(@black-0, .2);
    }
  }

  &ButtonRight {
    border-left: none;
    border-radius: 0 @border-radius @border-radius 0;
  }

  &ButtonLeft {
    border-right: none;
    border-radius: @border-radius 0 0 @border-radius;
  }

  &Wrapper {
    display: flex;
  }
}

.InputWrapper {
  flex: 1 1 0;
  position: relative;

  .ant-input {
    font-size: 16px;
  }

  .ant-input-sm {
    padding: 12px 16px;
    font-size: 14px;
  }
}

.ButtonInsideArea {
  position: absolute;
  bottom: 4px;
  right: 4px;

  &-text {
    display: block;
    font-size: 12px;
    padding: 6px 8px;
    color: @action-info;
    cursor: pointer;
  }

  .ButtonDefault {
    height: 32px;
  }
}


.InputWrapper .ant-input-affix-wrapper {
  padding: 0 16px !important;
}

.MultiplicityCountWrapper {
  width: 65px;
  height: 32px;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: grid;
  place-items: center;
  background-color: @main !important;
  color: white;
}

.InputCountWrapper:hover {
  .InputCountActiveBorder {
    border-color: @main !important;
  }
}

.InputCountWrapper,
.InputCountButtonEmpty {
  width: 124px;
  height: 40px;
  font-weight: 500 !important;
}

.InputCountButtonLeft,
.MultiplicityCountWrapper,
.InputCountButtonRight {
  height: 100%;
}


button.InputCountButtonEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InputCountWrapper .ButtonDefaultWrapper button {
  background: #fff !important;
  color: black !important;
}

.InputCountWrapper .ButtonDefaultWrapper button[disabled] {
  color: #EEEEEF !important;
}

.MultiplicityCountWrapper_sum {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
}



.InputCountWrapperOld {
  .MultiplicityCountWrapper {
    width: 60px !important;
  }

  .InputCountWrapper,
  .InputCountButtonEmpty {
    width: 119px !important;
    height: 32px !important;
  }

  .InputCountButtonLeft, .InputCountButtonRight {
    height: 32px !important
  }
}