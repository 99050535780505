.ArrowsWrapper {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 50;
  top: 50%;
}

.Slider {
  .slick {
    &-arrow {
      display: none !important;
      z-index: 50;
      width: 46px;
      height: 46px;
      padding: 0;
      background-color: @white;
      border: 0;
      border-radius: 50%;
      box-shadow: 0 0 10px rgba(@black, .25);

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        text-indent: -9999px;
        overflow: hidden;
      }
    }

    &-prev {
      left: 25px;

      &:before {
        background-image: url("/Container/icons/arrow-prev.svg");
      }
    }

    &-next {
      right: 25px;

      &:before {
        background-image: url("/Container/icons/arrow-next.svg");
      }
    }

    &-prev,
    &-next {
      &:hover,
      &:focus {
        background-color: @main;
      }
    }
  }

  .slick-slide img {
    width: 100%;
  }

  .slick-slide > div {
    margin: 0 10px;
  }
}

@media screen and (min-width: 992px) {
  .Slider {
    .slick-arrow {
      display: block !important;
    }
  }
}
