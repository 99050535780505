.ant-tooltip {
    color: #0d0d0d !important;
}

.StatusComponent {
    display: inline-flex;
    align-items: center;
    min-height: 28px;
    border-radius: @border-radius;

    &-label,
    &-icon {
        padding: 6px;
    }

    &-icon {
        align-items: center;
        display: inline-flex;
        cursor: pointer;
    }

    &-icon-admin {
        display: inline-flex;
    }

    &-user,
    &-admin {
        text-transform: uppercase;
        font-size: 10px;
        color: @black-2;
        white-space: nowrap;
    }

    &-admin-icon {
        display: inline-flex;
        border-radius: 0 @border-radius @border-radius 0;
    }

    .StatusCircle {
        margin-left: 8px;
    }
}

.StatusComponentTooltip {
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;
    margin-top: 14px;
}

.StatusComponentTooltip div .ant-tooltip-inner {
    font-size: 12px;
    line-height: 16px;
    padding: 10px 16px;
}

.NewStatusCommentLarge {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -28px;
    margin-left: 13px;
    background-color: #FF3A29;
    opacity: 0.2;
}

.NewStatusCommentSmall {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-top: -26px;
    margin-left: 15px;
    background-color: #FF3A29;
    opacity: 0.8;
}

.CommentIconWrapper {
    margin-bottom: -6px;
}
