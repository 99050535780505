.AdminPanel-content {
  .SelectComponent-status .ant-select {
    min-width: 184px;
  }

  .InputWrapper-leftOversSearch {
      max-width: 288px;
      width: 100%;
  }

  &Back {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &Info {
    margin-left: 16px;
    color: @black-3;
  }
}

.BackWrapper {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: @black-3;
  cursor: pointer;

  &-icon {
    display: inline-flex;
    margin-right: 10px;
  }
}

