.AdminPanel-content {
  &TableHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    h2 {
      margin-bottom: 0;
    }
  }

  &InfoOrder {
    display: flex;
    margin-bottom: 24px;
  }

  &TotalCost {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: @black-2;
  }

  &TotalValue {
    font-size: 24px;
    font-weight: 500;
    margin-left: 12px;
    color: @black;
  }

  &Cell-rating {

    .ButtonDefault-icon {
      align-items: center;
      display: flex;
      height: 100%;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }
}

.InfoOrderWrapper {
  flex: 1 1 0;
  padding: 24px;
  box-sizing: border-box;
  background-color: @white;
  border-radius: 2px;
  font-size: 14px;
  border: 1px solid rgba(@black,.1);

  & + .InfoOrderWrapper {
    margin-left: 32px;
  }

  &-row {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(250px, 6fr);
    column-gap: 30px;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  &-area {
    width: 100%;
  }

  &-label {
    color: @black-3;
  }

  &-value-rating {
    display: flex;
    margin-top: 5px;
  }
}

.SelectWrapper div div .ant-select-selection-item .DisplayRow .StatusRound {
  margin-top: 20px;
}

@media screen and (max-width: 991px) {
  .AdminPanel-content {
      min-width: 1000px;
  }
}
