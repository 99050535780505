.CheckboxWrapper {
  display: flex;

  .ant-checkbox-inner {
    border-color: rgba(@black-0,.1);
  }

  .ant-checkbox-disabled .ant-checkbox-input {
    border-color: rgba(@black-0,.2);
    background-color: rgba(@black-0,.1);
  }
}

.CheckboxText {
  font-size: 16px;
  line-height: 19px;
  color: @black;
}
