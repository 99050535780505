.DatePickerWrapper {
  .ant-picker {
    &-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: @black;
  }

  &-input {
    flex-direction: row-reverse;
  }

  &-suffix {
    margin: 0 20px 0 8px !important;
    color: rgba(@black,.8);
  }

  &-header {
    &-view button:hover {
        color: @main;
    }

    button {
      font-weight: bold;
    }
  }

  &-panel-container {
    box-shadow: 0 0 20px 5px rgba(@black-0,.05);
  }

  &-today-btn {
    color: @black;

    &:active,
    &:hover {
      color: @main;
    }
  }

    &:hover,
    &.focused {
      border-color: @main;
      box-shadow: none;
    }
  }
}

.Error {
  &PickerWrapper .ant-picker {
    border-color: @action-danger;
  }

  &Text {
    font-size: 10px;
    color: @action-danger;
    margin-top: 2px;
    margin-bottom: -16px;
  }
}

.RangePickerWrapper  {
    .anticon {
    color: @black-3;
  }

  .ant-picker-small {
    padding: 12px 16px;
  }

  .ant-picker-input {
    width: 85px;
  }
}

@media screen and (max-width: 991px) {
  .InputWrapper .ant-input {
    height: @height-sm;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
