.AdminPanel {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  &-sidebar {
    width: 200px;
    position: relative;
    flex-shrink: 0;
    background: @white;
    box-shadow: 6px 0 18px rgba(@black,.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &Body {
      padding: 32px 24px;
    }

    &Footer {
      padding: 32px;
    }
  }

  &-rangepicker-wrapper {
    width: 264px;
  }

  &-row {
    display: flex;
    margin-bottom: 12px;
    padding: 9px 12px;
  }

  &-user {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &Icon {
      height: 36px;
      width: 36px;
      margin-right: 8px;
    }
  }

  &-info {
    line-height: 1.25;

    &Mail {
      font-size: 10px;
      color: @black-3;
    }
  }

  &-menu {
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 18px;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: -24px;
      right: -24px;
      border-bottom: 1px solid @grey-2;
    }

    &Item {
      border-radius: 2px;

      &:hover {
        .AdminPanel {
          &-menuIcon svg {
            fill: @main;
          }

          &-menuLink {
            color: @main;
          }
        }
      }
    }

    &Icon {
      height: 20px;
      margin-right: 12px;

        svg {
          fill: @black-3;
          transition: fill 300ms;
        }
    }

    &Link {
      color: @black-3;
      transition: color 300ms;
    }
  }

  &-link {
    cursor: pointer;
    color: @black-3;

    &Icon {
      height: 20px;
      margin-right: 12px;
    }

    &Highlig {
      color: @action-info;
    }
  }

  &-activeItem {
    &,
    &:hover {
      cursor: default;
      background: @light-primary;

      .AdminPanel {
        &-menuIcon svg {
          fill: @main;
        }

        &-menuLink {
          color: @black;
        }
      }
    }
  }

  &-content {
    height: 100vh;
    padding: 42px 32px;
    flex: 1 1 0;
    overflow: auto;
    background: @grey-bg;

    &Header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .InputWrapper input {
        height: @height-sm;
      }
    }

    &Title {
      display: flex;
      align-items: baseline;
      margin-bottom: 24px;

      &_with {
        justify-content: space-between;
      }
    }

    &Left {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 0;

      .AdminPanel-contentCell {
        margin-right: 24px;
        margin-bottom: 8px;
      }
    }

    &Right {
      .AdminPanel-contentCell {
        margin-left: 24px;
      }
    }
  }
}

.Logo {
  &-admin svg {
    height: 32px;
    margin-bottom: 32px;
  }
}

.Copyright {
  &-admin {
    font-size: 12px;
  }
}

@media screen and (min-width: 992px) {
  .AdminPanel-sidebar {
    width: 256px;
  }
}

//background: url("/Container/icons/alcohol.svg");



