.InputPlaceholderInfoOff {
  display: none !important;
}

.CustomerData {
  background-color: @white;
  border: 1px solid rgba(@black-0, 0.1);
  box-sizing: border-box;
  padding: 24px;
  border-radius: @border-radius;
  font-size: 14px;
  width: 100%;

  &-row,
  &-rowValue {
    margin-bottom: 8px;
  }

  &-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &h3 {
      font-size: 16px !important;
    }
  }

  &-label {
    color: rgba(@black, 0.6);
  }

  &-value {
    font-size: 16px;
    font-weight: 500;
  }
}

.OrderDataInputOn {
  .InputWrapper input {
    padding-top: 21px !important;
    padding-bottom: 11px !important;
  }
}

.InputNamePlaceholderInfoOn {
    top: 8px !important;
    font-size: 12px !important;
    line-height: 14px !important;
}

.InputPhonePlaceholderInfoOn {
    top: 8px !important;
    font-size: 12px !important;
    line-height: 14px !important;
}

@media screen and (max-width: 991px) {
  .CustomerData {
    padding: 20px;
  }
  .InputNamePlaceholderInfoOn {
    top: 4px !important;
  }
  .InputPhonePlaceholderInfoOn {
    top: 4px !important;
  }
}

@media screen and (min-width: 1113px) {
  .CustomerData {
    width: 320px;
  }
}

@media screen and (min-height: 900px) and (min-width: 992px) {
  .FixedCustomerData {
    margin-bottom: 9px;
    padding-bottom: 14px;
    position: relative !important;
  }
}

.OrderData {
    &-rowValue {
        position: relative;
    }
}

.CustomerDataCart {
  width: 412px;
  height: fit-content;
  margin-left: auto;
}

.CustomerDataCartTitle {
  font-family: Rubik;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}

.CustomerData-row {
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
}

.CustomerData-value_total {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: right;
}

.CustomerData-amount {
  height: 35px;
}

.CustomerData-warning {
  max-height: fit-content!important;
  gap: 10px;
  padding-left: 3% !important;
  padding-right: 3% !important;
}

.CustomerData-amount, .CustomerData-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 100%;
  background: @grey-bg;
  border-radius: 4px;
  margin-bottom: 12px;
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

}

.dots {
  flex-grow: 1;
  height: 1px;
  background-image: linear-gradient(
          90deg,
          currentColor 25%,
          transparent 25%,
          transparent 50%,
          currentColor 50%,
          currentColor 75%,
          transparent 75%,
          transparent 100%
  );
  background-size: 4px 1px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.CustomerData-label,
.CustomerData-value {
  color: #000;
}

.CustomerData-label {
  color: #27293A;
}

.CustomerData-row_info {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

div.PriceList-sidebar {
  position: initial !important;
}

.CustomerData-input_side {
  resize: none;
  height: 64px;
}

.CustomerData-label_total {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.CustomerData-button-order {
  height: 56px;
  width: 100%;
  span {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
  }
}

