.OrderData {
  background-color: @white;
  border: 1px solid rgba(@black-0, 0.1);
  box-sizing: border-box;
  padding: 24px;
  border-radius: @border-radius;
  font-size: 14px;
  width: 100%;

  &-title-h2 {
    font-weight: 500;
    font-size: 18px !important;
    display: flex;
    justify-content: space-between;
  }

  &-row,
  &-rowValue {
    margin-bottom: 8px;


    &:last-child {
      margin-bottom: 0;
    }
  }

  &-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-label {
    color: rgba(@black, 0.6);
  }

  &-input {
    display: block;
    font-size: 16px;
  }

  &-info-label {
    font-size: 12px;
    color: @black;
    opacity: 0.75;
    line-height: 180%;
  }
}

.ErrorOrder {
  &-data {
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
    color: @action-danger;
  }
}

.InfoIconWrapper {
  position: absolute;
  width: 48px;
  height: 48px;
  top: 0;
  right: 0;
  border-bottom-left-radius: 80%;
  background: @light-info;
}

@media screen and (min-width: 1113px) {
  .OrderData {
    width: 320px;
  }
}

@media screen and (min-height: 900px) and (min-width: 992px) {
  .OrderData {
    position: relative !important;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 991px) {
  .OrderData {
    margin-bottom: 9px;
    padding: 20px;
  }

  .SmallComponentStyle {
    height: 40px !important;
    font-size: 16px !important;
  }
}
