.OrdersFilterWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;

  padding-top: 24px;

  .RangePickerWrapper .ant-picker {
    height: 48px;
  }

  .OrdersFilterStatusWrapper .SelectWrapper .ant-select .ant-select-selector {
    height: 48px;

    .ant-select-selection-item .OrdersFilterStatusAll {
      color: rgba(39, 41, 58, 0.4) !important;
      margin-top: -6px;
    }

    .ant-select-selection-placeholder {
      margin-top: -2px;
    }
  }
}

.OrdersFilterStatusWrapper {
  width: 220px;

  .SelectWrapper {
    .SelectComponent {
      width: 100%;
    }
  }
}

.OrdersFilterHallTreeSelect {
  width: 220px !important;

  .ant-select-selector {
    height: 48px !important;
    padding-top: 4px !important;

    .ant-select-selection-overflow {
      overflow-y: auto;
      max-height: 40px;
    }
    .ant-select-selection-placeholder {
      padding-left: 4px;
    }
  }
}

.OrdersFilterStatusOption {
  height: 40px;
}

.OrdersFilterStatusAll {
  padding-top: 4px !important;
  color: #25BA7C !important;
}

.OrdersFilterStatus {
  display: flex;
  flex-direction: row;
  gap: 10px;

  padding-top: 8px;

  span {
    color: #525461;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
  }
}

.OrdersFilterStatusBadge {
  margin-top: 6px;

  border-radius: 3px;
  width: 6px;
  height: 6px;
}

.ant-select-selection-item {
  .OrdersFilterStatus {
    padding-top: 16px;
  }
}