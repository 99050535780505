.ComponentWrapper {
  display: flex;
  flex-direction: column;
}

.HeaderWrapper {
  margin-left: auto;
  padding: 16px 0 16px 16px;
  display: flex;
}

.PaginationWrapper {
  padding: 24px 0;
}

.DeleteButtonWrapper {
  padding-right: 16px;
}

.TableWrapper {
  .ant-table-thead > tr {
    &.ant-table-row:hover > td {
      background: #fff !important;
    }

    & > th {
      color: rgba(@black-3, .7);
      text-transform: uppercase;
      font-size: 11px;
      line-height: 1.33;
      border-color: #DEDFE1;
    }
  }

  .ant-table-tbody > tr {
    & > td {
      color: @black-3;
    }

    & > .TableWrapper-black {
      color: @black;
    }

    & > .TableWrapper-black2 {
      color: @black-2;
    }
  }
}

.ant-table-column-sort, .ant-table-column-has-sorters {
  background-color: #fff;
}

.ant-table-column-has-sorters:hover {
  background-color: #EEEEEF !important;
}


.TableWithClickWrapper {
  .ant-table-tbody > tr {
    &.ant-table-row:hover > td {
      cursor: pointer;
    }
  }
}

.TableWrapperWithRowClick {
  .ant-table-tbody > tr:hover {
      background: #fff !important;
      box-shadow: 0px 0px 8px rgba(39, 41, 58, 0.12) !important;
      cursor: pointer !important;
    }
}

.TableIconWrapper {
  display: flex;
  justify-content: space-between;
}

.TableIcon svg:hover {
  color: #737373;
}
