.Tab {
  &-header {
    display: flex;
    flex-direction: row;
  }
  &-rectangle-wrapper {
    height: 100%;
    width: 1px;
    background: #eeeeef;
  }
  &-rectangle {
    background: #757681 !important;
    opacity: 0.2;
    width: 1px;
    height: 26px;
    margin-top: 12px;
  }

  &-links {
    width: 196px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    border: none;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    border-radius: @border-radius @border-radius 0 0;
    transition: 0.3s;
    font-weight: 500;
    color: #27293a !important;
    color: rgba(@black-2, 0.6);
    background-color: #eeeeef;

    &.active {
      background-color: @white;
      color: #27293a;
    }

    svg {
      margin-right: 8px;
    }
  }

  &-body {
    background-color: @white;
    padding: 16px 0;
  }
}

.PriceList {
  flex: 1 1 0;

  &-sidebar {
    flex: 1 1 0;
  }

  &-header {
    margin: 0 0 24px 0;
  }

  &-customerData {
    margin-bottom: 16px;
  }

  &Columns {
    flex: 1 1 auto;
    max-width: calc(100% - 344px);
  }
}

@media screen and (min-height: 900px) and (min-width: 992px) {
  .PriceList-sidebar {
    position: fixed !important;
    padding-top: 0;
    padding-bottom: 20px;
  }
}

.PriceListSelectPriceWrapper {
  padding-bottom: 16px;
  width: 300px;
}

@media screen and (max-width: 991px) {
  .PriceListSelectPriceWrapper {
    width: 100% !important;
  }
}

.PriceListSelectPriceWrapper .SelectWrapper .ant-select {
  width: 100% !important;
}

.AccessPriceItemMinSumContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #E0EDFD;
  border-radius: 27px;
  padding: 4px;
  gap: 5px;
  width: fit-content;
  margin-top: 8px;
}

.AccessPriceItemMinSumContainerText {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #2D8AF1;
}

.AccessPriceWrapperCart {
  display: flex;
}

.Tab-body_cart {
  .PriceTable-bodyInner {
    max-height: 100% !important;
    overflow: initial !important;
    padding-right: 0 !important;
    width: 100% !important;
  }

  .PriceTable-item {
    .InputCountWrapper {
      .InputCountButtonLeft, .MultiplicityCountWrapperActiveBorder {
        background: @white !important;
        color: @main !important;
      }
    }
  }
}

.CartItemsHeaderContainer {
  display: flex;
  gap: 40px;
  padding-left: 24px;
  border-bottom: 2px solid #EEEFF1;
  padding-bottom: 18px;
}

.CartItemsHeaderElWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  gap: 10px;

  .CheckboxText {
    display: none;
  }
}

.CartItemsHeaderElWrapper {
  svg:hover {
    cursor: pointer;
  }
}

.Tab-body_empty {
  background: @grey-bg;
}

.Tab-body_empty_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Tab-body_cart_empty_header {
  font-family: Rubik, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 32px;
}

.Tab-body_cart_empty_button {
  font-size: 14px !important;
}

.Tab-body_price_empty_header {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 16px;
}

.Tab-body_price_empty_sub-header {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: @black-3;
  margin-bottom: 24px;
}

.ChangeCheckModal_changed_item_deleted_container_title {
  color: @action-danger;
}

.ChangeCheckModal_changed_item_price_old {
  color: @black-3;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: line-through;
}

.ChangeCheckModal_changed_item_price_new {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.ChangeCheckModal_changed_item_price_new_TooltipIcon:hover {
  cursor: pointer;
  opacity: 0.5;
}

.ChangeCheckModal_changed_item_title {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.ChangeCheckModal_changed_items_container {
  display: flex;
  flex-direction: column;
}

.ChangeCheckModal_changed_item_container {
  border-bottom: 1px solid @grey-3;
  padding: 22px 0;
  gap: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ChangeCheckModal_changed_item_price_container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  div {
    white-space: nowrap;
  }
}

.ChangeCheckModal_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
}

.ChangeCheckModal_wrapper {
  width: 758px;

  .CloseWrapper {
    height: 0;
  }

  .ModalContentWrapper {
    padding: 32px 40px;
  }
}

.ChangeCheckModal_changed_item_available-soon_container {
  min-width: 125px;
}

.ChangeCheckModal_changed_item_deleted_container {
  min-width: 100px;
}

.ChangeCheckModal_buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  height: 40px;
  width: 100%;
  margin-top: 24px;

  button {
    height: 40px;
  }

  .ChangeCheckModal_button_close_modal {
    background: @grey-3 !important;
    border-color: @grey-3 !important;
    color: black !important;

    &:hover {
      background:  #C2C2C2FF !important;
      border-color: #C2C2C2FF !important;
    }
  }
}

.PriceListSearchInput {
  margin-top: 16px;
  height: 48px;
}