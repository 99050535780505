.LoginPage {
  background-color: @white;

  &-firstScreen {
    padding: 0 15px;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, transparent 61%, @main 39%);
  }

  &-screen {
    display: flex;
    flex: 1 1 0;

    .Container {
      justify-content: flex-end;
    }
  }

  &-offer {
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &-title {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 50px;
  }

  &-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    &Wrap {
      position: relative;
    }
  }

  &-slider {
    padding: 26px 0;
    position: relative;
    background: @white;

    & > .Container {
      display: block;
      max-width: 100%;
    }
  }

  &-content {
    padding: 36px 15px 43px;
    background-color: @main-lighter;
    position: relative;
    overflow: hidden;
  }
}

.ServiceInfo {
  background-color: @white;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 36px;
  padding: 34px 28px;
  box-shadow: 8px 0 0 @main inset;

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &-title {
    margin-bottom: 0;
  }

  &-icon {
    margin-right: 24px;
  }

  &-text {
    font-size: 18px;
    line-height: 1.75;
  }
}

.ModalLogin {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(@black-0,.6);
  z-index: 500;
  display: none;

  &.show {
    display: block;
  }

  &-dialog {
    width: 360px;
    position: absolute;
    background: @white;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 1000;
    border-radius: @border-radius;

    &:before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(@black-0, .6);
      z-index: 500;
      display: none;
    }
  }

  &-body {
    padding: 25px 24px 32px;
  }

  &-title {
    margin-bottom: 24px;
    color: @black-2 !important;
  }

  &-close {
    position: absolute;
    right: 15px;
    top: 15px;
    height: 24px;
    cursor: pointer;
  }

  &-row {
    margin-bottom: 16px;
  }

  &-icon {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}

.ErrorMessage {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #FF7454;
}

@media screen and (min-width: 992px) {
  .LoginPage {
    &-firstScreen {
      padding: 0 45px;
      height: 960px;

      .ButtonDefault,
      .ButtonPrimary {
        padding: 0 50px;
      }
    }

    &-title {
      font-size: 75px;
      margin-bottom: 56px;
    }

    &-slider {
      padding: 56px 0;
    }

    &-content {
      padding: 96px 45px 143px;

      &:before {
        content: "";
        position: absolute;
        top: 30px;
        bottom: 40px;
        width: 412px;
        left: 65%;
        background: url("/Container/icons/bg-round-2.png") no-repeat 0 0;
      }
    }

    .ServiceInfo {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 116px;
      padding: 64px 38px;

      &-header {
        margin-bottom: 36px;
      }
    }

    &-image:before {
      content: '';
      position: absolute;
      left: -220px;
      right: 0;
      top: 0;
      bottom: 0;
      background: url(/Container/icons/bg-round-1.png) no-repeat left bottom, linear-gradient(90deg, transparent 435px, #25BA7C 435px), #fff;
    }
  }
}


.InputPlaceholderInfo {
  padding-left: 16px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #27293A;
  opacity: 0.4;
  position: absolute;
}


.InputPasswordPlaceholderInfoOn {
  top: 256px !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

.InputLoginPlaceholderInfoOn {
  top: 181px !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

@media screen and (min-width: 768px) {
  .LoginPage {
    &-title {
      font-size: 60px;
    }
  }
}

@media screen and (max-width: 991px) {
  .LoginPage {
    &-screen {
      height: 100vh;
    }

    &-offer {
      transform: translateY(-40%);

      .LoginPage-title,
      .ButtonDefaultWrapper {
        padding: 0 10px;
      }
    }
  }

  .InputLoginPlaceholderInfoOff {
    display: none !important;
  }

  .InputPasswordPlaceholderInfoOff {
    top: 250px !important;
  }

  .InputPasswordPlaceholderInfoOn {
    top: 240px !important;
  }

  .InputLoginPlaceholderInfoOn {
    top: 176px !important;
  }
}

@media screen and (max-width: 1366px) {
  .LoginPage {
    &-imageWrap {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(@white,.5);
      }
    }
  }
}
