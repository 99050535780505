.Leftover {
    flex: 1 1 0;

    &-sidebar {
        flex: 1 1 0;
        padding-top: 64px;
    }

    &-header {
        margin: 0 0 24px 0;
    }

    &-searchBar {
        margin-bottom: 24px;
    }

    &Columns {
        flex: 1 1 auto;
        max-width: calc(100% - 344px);
    }
}

@media screen and (max-width: 1113px) {
  .Leftover-sidebar {
      padding-top: 24px;
  }
}
