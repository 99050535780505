.StatusBadgeWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
}

.StatusBadgeCircle {
  width: 8px;
  height: 8px;
  margin: 8px 0px;
  border-radius: 100%;
}

.StatusBadgeName {
  padding-left: 8px;
}
