.RadioWrapper .ant-radio-inner {
  border-color: #d9d9d9;
}

.RadioWrapper .ant-radio-wrapper .ant-radio .ant-radio-inner {
  background-color: #fff;
}

.RadioWrapper .ant-radio-checked .ant-radio-inner::after {
  background-color: #fff;
}
.RadioWrapper .ant-radio-checked .ant-radio-inner {
  background-color: @main !important;
}
.RadioWrapper .ant-radio-disabled .ant-radio-inner {
  border-color: rgba(217, 217, 217, 0.5) !important;
  background-color: #f6f6fb !important;
  opacity: 0.6;
}
