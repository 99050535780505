.FAQLicenseWrapper {
  display: flex;
  flex-direction: column;
}

.FAQLicenseBack {
  padding-top: 10px;
  padding-right: 16px;
}

.FAQLicenseBack:hover {
  cursor: pointer;

  span svg path {
    fill: @main;
  }

}

.FAQLicenseImages {
  flex-wrap: wrap;
}

.FAQLicenseBlock {
  height: 1160px;
  width: 820px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 32px auto;
  position: relative;
}

.RostovLicenseOne {
  background-image: url("/Container/FAQRoute/FAQLicense/img/rostov_one.jpg");
}

.RostovLicenseTwo {
  background-image: url("/Container/FAQRoute/FAQLicense/img/rostov_two.jpg");
}

.TulaLicenseOne {
  background-image: url("/Container/FAQRoute/FAQLicense/img/tula_one.jpg");
}

.TulaLicenseTwo {
  background-image: url("/Container/FAQRoute/FAQLicense/img/tula_two.jpg");
}

.KrasnodarLicenseOne {
  background-image: url("/Container/FAQRoute/FAQLicense/img/krasnodar_two.png");
}

.KrasnodarLicenseTwo {
  background-image: url("/Container/FAQRoute/FAQLicense/img/krasnodar_one.png");
}

.FAQLicenseBlockWaterMark {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  left: 50%;
  font-size: 90px;
  opacity: 0.2;
}