.BackTopWrapper {
  display: none;

  .ant-back-top {
    width: 200px;
    border-radius: 2px;
    color: #268664;
    background: #def5eb;
    right: auto;
    left: calc(50% + 340px);
    bottom: 100px;

    div {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
    }

    .anticon {
      margin-right: 5px;
    }
  }
}

@media screen and (min-width: 992px) {
  .BackTopWrapper {
    display: block;
  }
}
