.AdminPanel-content {
  .InputWrapper-leftOversSearch {
    max-width: 288px;
    width: 100%;
  }

  &Table_with-sideBar {
    display: flex;
  }

  &Sidebar {
    width: 352px;
    margin-left: 32px;
    flex-shrink: 0;
  }
}

.BlockInfoWrapper {
  background-color: @white;
  border: 1px solid rgba(@black,.1);
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 16px;
  margin-bottom: 24px;

  &-head {
    padding: 12px 24px;
    border-bottom: 1px solid @grey-2;

    h2 {
      margin-bottom: 0;
    }
  }

  &-body {
    padding: 0 24px 30px 24px;
  }

  &-row {
    margin-top: 20px;
  }

  &-label {
    font-size: 11px;
    text-transform: uppercase;
    color: @black-3;
  }
}


