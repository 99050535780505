.SuccessModalWrapper {
  position: fixed;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9999 !important;
  background: rgba(0, 0, 0, 0.32);
}

.SuccessWindowWrapper {
  padding-top: 17px;
  width: 444px;
  height: 427px;
  background-color: #B9E9D5;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #268664;
}
