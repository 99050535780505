.ModalFavoritesBlockText {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  height: 81px !important;
  width: 279px;
  left: 32px;
  top: 32px;
  border-radius: nullpx;
  color: #27293a;
  margin-top: 20px;
  margin-left: 25px;
  margin-bottom: 25px;
}

.ModalFavoritesBlock {
  width: 330px;
  height: 170px;
  left: 0%;
  top: 0%;
  border-radius: 2px;
  background: #ffffff;
  border-radius: 2px;
}

.ButtonFavoritesModalCancel {
  color: #757681;
  background: white;
  border: 1px solid #757681;
  height: 48px;
  width: 140px;
  font-family: Rubik;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-left: 20px;
  align-items: center;
}

.ButtonFavoritesModalCancel:hover {
  color: #757681;
  background: rgb(243, 243, 243);
  border: 1px solid #757681;
}

.ButtonFavoritesModalOk {
  border: 0;
  height: 48px;
  width: 140px;
  font-family: Rubik;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-left: 10px;
}
