.SwitchLabel {
    font-size: 16px;
    margin-left: 12px;
}

.SwitchWrapper {
    display: flex;
    align-items: center;

    .ant-switch {
        height: 20px;
        min-width: 36px;
    }

    .ant-switch-handle {
        width: 16px;
        height: 16px;
    }

    .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 18px);
    }
}
