.PriceTable {
  &-head,
  &-row {
    display: grid;
  }

  &-head {
    padding: 2px 0;
    background-color: @light-primary;

    .PriceTable-item {
      font-size: 12px;
      font-weight: 500;
      color: rgba(@black, 0.5);

      &Category {
        display: inline-flex;
        color: @main;
        padding-left: 15px;
      }

      &Sum {
        padding-right: 15px;
      }
    }
  }

  &-row {
    padding: 10px 0;
    position: relative;
    border-bottom: 1px solid rgba(@black-0, 0.1);

    .PriceTable-item {
      &Sum {
        font-size: 20px;
        font-weight: 600;
      }
    }

    &-action {
      .PriceTable-itemPrice span:not(.s-show) {
        background: url("/Container/icons/bg-action-price.svg") 0 center
          no-repeat;
        background-size: contain;
        padding-left: 9px;
        margin-left: -9px;
        height: 28px;
        display: inline-flex;
        align-items: center;
        padding-right: 9px;
      }

      .PriceTable-itemName {
        display: flex;

        &:before {
          content: "";
          width: 20px;
          height: 20px;
          margin-right: 8px;
          background: url("/Container/icons/action.svg") 0 center no-repeat;
          display: inline-flex;
          flex-shrink: 0;
        }
      }
    }
  }

  &:last-child {
    .PriceTable-row:last-child {
      border-bottom: 0;
    }
  }

  &-item {
    padding: 15px 0;

    &Sum {
      text-align: right;
    }

    &Title {
      display: flex;
    }
  }

  & + .PriceTable {
    margin-top: 20px;
  }
}

.s-show {
  color: @black-2;
}

@media screen and (max-width: 991px) {
  .s-hide {
    display: none !important;
  }

  .Tab {
    &-links {
      width: 150px;
    }

    &-body {
      padding-left: 14px;
      padding-right: 14px;
    }
  }

  .PriceTable {
    &-row .PriceTable-itemSum {
      font-size: 18px;
    }

    &-item {
      display: flex;
      align-items: center;

      &:not(.PriceTable-itemTitle) {
        justify-content: space-between;
      }
    }

    &-itemCategoryMobile-ArrowWrapper {
      padding-right: 16px;
    }
  }
}

.PriceTable-itemCategory {
  width: 200px;
}

.PriceTableCategoryHead:hover {
  cursor: pointer;
  opacity: 0.8 !important;
}

.Tab-body {
  padding-bottom: 20px;
  margin-top: 16px;
}

@media screen and (min-width: 992px) {
  .s-show {
    display: none;
  }

  .PriceTable-head,
  .PriceTable-row {
    grid-template-rows: repeat(1, auto);
    grid-template-columns:
      minmax(250px, 4.2fr) minmax(30px, 30px) minmax(85px, 1fr) minmax(
        85px,
        1fr
      )
      minmax(80px, 1fr)
      minmax(90px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(10px, 0.8fr);
    align-items: center;
    gap: 5px;
  }

  .PriceTable-body {
    width: 100%;

    &Inner {
      max-height: calc(100vh - 300px);
      overflow-y: auto;
      padding-right: 5px;
      width: calc(100% + 13px);
      scrollbar-width: thin; // for FF
    }

    .PriceTable-itemSum span {
      font-size: 16px;
    }
  }
}

.PopoverFavoritesBlock {
  border-radius: 4px;
  position: relative;
  text-align: center;
  vertical-align: center;
  background: #515360;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16);
}

.PopoverFavoritesText {
  color: white;
  width: 255px !important;
  padding: 7px;
}

.UnionRed:hover {
  cursor: pointer;
}

.Union:hover {
  cursor: pointer;
}

.UnionRedHover:hover {
  fill: #ff7454;
}

.UnionRed {
  fill: #ff7454;
}

.Union {
  fill: #757681;
}

.UnionBlock {
  fill: #dedfe1;
}

.UnionBlock:hover {
  fill: #dedfe1;
  cursor: auto;
}

.PriceTableItemCategoryTitle {
    color: black;
}

.PriceTableItemTitle {
    font-weight: 400 !important;
}

.PriceTable-head {
  display: flex;
  justify-content: space-between;
}

.PriceTable-header {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
}

.PriceTable-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 32px;
  border-bottom: 2px solid #EEEFF1;
}

.PriceTable-row-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.PriceTable-itemName-subgroup {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  gap: 32px;
}

.PriceTable_cart {
  margin-top: 0px !important;
}

.PriceTable-itemCount {
  button.InputCountButtonLeft,
  input.InputCountActiveBorder,
  button.InputCountButtonRight,
  button.InputCountButtonEmpty {
    background-color: @primary !important;
    color: white !important;
  }

}

.PriceTable-item .InputCountWrapper .InputCountButtonEmpty_disabled {
  background-color: @grey-2 !important;
  border-color: @grey-2 !important;
  span {
    color: @black-3 !important;
  }

}

.PriceTable-itemName {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.PriceTable-itemName-subgroup {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #757681;
  margin-top: 7.5px;
}

.PriceTable-price-main {
  font-family: Rubik;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 8px;
}

.PriceTable-price-main_promo {
  border-radius: 4px;
  background-image: url(/Container/icons/subtract.svg);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;
  white-space: nowrap;
}

.PriceTable-price-secondary {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  color: #757681;
  margin-right: 40px;
}

.PriceTable-item {
  input.ant-input,
  button.ant-btn {
    transition: none !important;
  }
}

.PriceTable_container_header {
  display: flex;
  align-items: center;
}

.Tab-body_cart .PriceTable-row-container {
  padding: 0 24px !important;
}

.Tab-body_cart .PriceTable-itemName-subgroup {
  svg:hover {
    cursor: pointer;
  }
}

.PriceTable-info-badge_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.PriceTable-isAvailableSoon_text {
  color: #2d8af1;
}

