.Button {
  &Default,
  &Primary,
  &PrimaryDisabled,
  &DefaultDisabled {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    box-shadow: none;

    &.ant-btn-sm {
      font-size: 14px;
    }
  }
}

.ButtonDefault {
  border-color: @main;

  &:hover,
  &:focus,
  &:active {
    background-color: @main-dark;
    border-color: @main-dark;
    color: @white;
  }
}

.ButtonPrimary {
    background-color: @main;
    border: 2px solid @white;
    color: @white;

  &:hover,
  &:focus,
  &:active {
    background-color: @white;
    border-color: @white;
    color: @main;
  }
}

.ButtonPrimary,
.ButtonDefault {
    &Disabled {
    cursor: not-allowed;
    background-color: @grey-3 !important;
    border-color: @grey-3 !important;
    color: rgba(@black,.4) !important;
  }
}

@media screen and (max-width: 991px) {
  .ButtonDefault,
  .ButtonPrimary {
    &,
    &Disabled {
      height: @height-sm;
      line-height: @height-sm;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.ButtonIconWrapper button {
  background: #FFFFFF;
  border: 1px solid #DEDFE1;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0 9px !important;
  height: 40px !important;
  display: grid;
  place-items: center;

  &:hover,
  &:focus,
  &:active {
    background-color: #EEEEEF;
    border-color: #DEDFE1;
    color: #27293A;
  }
}

.ButtonIconChildrenWrapper {
  margin-top: auto;
}

.ButtonCancelWrapper button {
  background: #EEEEEF;
  border-radius: 2px;
  align-items: center;
  color: #525461 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border: none !important;

  &:hover,
  &:focus,
  &:active {
    background-color: #EEEEEF;
    border-color: #DEDFE1;
    color: #27293A;
  }
}
