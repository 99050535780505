.AdminPanel-content {
  .InputWrapper-productsSearch {
      max-width: 384px;
      width: 100%;
  }
}

.ButtonDefault-unloadToExcel .ButtonDefault {
  background: #E7F1EC;
  border: 1px solid #107A40;
  color: #10783F;

  &-icon {
    display: inline-flex;
    margin-right: 10px;
  }
}

.TableWrapper {
  &-itemName {
    display: flex;

    &-action {
      &:before {
        content: "";
        width: 20px;
        margin-right: 8px;
        background: url("/Container/icons/action.svg") 0 center no-repeat;
        display: inline-flex;
        flex-shrink: 0;
      }
    }
  }

  &-itemPrice-action span {
    background: url("/Container/icons/bg-action-price.svg") 0 center no-repeat;
    background-size: contain;
    padding-left: 9px;
    margin-left: -9px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    padding-right: 9px;
  }
}

