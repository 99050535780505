.SwitchAccessWrapper {
  background: #757681;
  opacity: 0.5;
  border: 1px solid #757681;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
}

.SwitchAccessItem {
  width: 152px;
  height: 44px;
  display: grid;
  place-items: center;
  margin: 1px 1px 0 1px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  cursor: pointer;
}

.SwitchAccessItemActive {
  background: #fff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  color: #000 !important;
}
