@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.FAQWrapper {
  flex: 1 1 0;

  &-header {
      margin: 0 0 24px 0;
  }

  &-list {
    margin-top: 40px;
  }

  ol {
    padding-left: 15px;

    li {
      padding-left: 6px;
      margin-bottom: 8px;
    }
  }

  a {
    color: @action-info;
  }

  .f-right {
    display: block;
    margin: 0 0 15px 0;
  }

  .highlight {
    display: inline-block;
    padding: 2px 8px;
    background: #DEF5EB;
    font-style: italic;
    font-weight: 500;
  }

  .d-block {
    padding: 8px 15px;
  }

  .badge {
    display: inline-block;
    padding: 7px 8px;
    border-radius: @border-radius;
    color: @black-2;
    font-size: 10px;
    text-transform: uppercase;
  }

  .bg {
    &-primary {
      background: #DAD7FE;
    }

    &-success {
      background: #CCF8FE;
    }

    &-danger {
      background: #FFE5D3;
    }

    &-warning {
      background: #FFF5CC;
    }

    &-info {
      background: #E2FBD7;
    }
  }
}

.FAQItem {
  box-shadow: 0 1px 0 0 #C8CCD5 inset;
  animation: fadeIn 0.2s ease-in-out;

  &:hover,
  &-open {
    position: relative;
    z-index: 1;
    background: @white;
    box-shadow: 0 1px 0 0 @white inset, 0 1px 0 0 @white;
    animation: fadeIn 0.2s ease-in-out;
  }

  &-open {
    p:last-child {
      margin-bottom: 0;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;

      &:hover {
        cursor: pointer;
    }
  }

  &-body {
    padding: 5px 20px 20px 20px;
    line-height: 1.5;
  }

  &-title {
    font-weight: 500;
    font-size: 20px;
  }

  &-icon {
    height: 24px;
  }
}

@media screen and (min-width: 768px) {
  .FAQWrapper {
    .f-right {
      float: right;
      margin: 0 0 5px 15px;
    }
  }

  .FAQItem {
    &-body {
      padding: 5px 40px 40px 40px;
    }
  }
}

.FAQIconPlus {
  transform: rotate(-45deg); /* Equal to rotateZ(45deg) */
  transition-duration: 0.2s;
}

.FAQIconCross {
  transform: rotate(0deg); /* Equal to rotateZ(45deg) */
  transition-duration: 0.2s;
}

.FAQItemPanel .ant-collapse-header {
  padding: 27px 24px 20px 24px !important;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #27293A;
}

.FAQItemPanel:hover, .FAQItemActive {
  position: relative;
  z-index: 1;
  background: @white;
  box-shadow: 0 1px 0 -1px @white inset, 0 1px 0 2px @white;
  //animation: fadeIn 0.2s ease-in-out;
}

.FAQItemPanelRectangle {
  z-index: 0;
  width: 100%;
  height: 2px;
  box-shadow: 0 -1px 0 0 #C8CCD5 inset;
}

.FAQBorderedImage {
  border: 1px solid #DEF5EB;
}

.LicenseBlock {
  width: 400px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
}

.LicenseBlock > a {
  padding-bottom: 8px;
}

.LicenseBlock > span, .LicenseBlock > a {
  text-decoration: underline;
  color: black !important;
}

.LicenseBlock > span:hover,
.LicenseBlock > a:hover {
  cursor: pointer;
  color: @main !important;
}

.LicenseAccordion > .ant-collapse-item > .ant-collapse-header {
  color: black;
  font-size: 14px;
  display: inline-block;
  padding: 0 !important;
}

.LicenseAccordion > .ant-collapse-item {
  margin: 16px 0;
}
