@import "./Filters/styles";

.Orders {
    flex: 1 1 0;

    &-header {
        margin: 0 0 24px 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        h1 {
            margin-bottom: 8px;
        }
    }

    &-right {
        padding-top: 24px;
    }
}

.OrdersTable, .LeftoverTable {
    background-color: @white;
    border: 1px solid rgba(@black-0,.1);
    border-radius: @border-radius;
    margin-top: 20px;

    &-head,
    &-body {
        display: grid;
    }

    &-head {
        padding: 10px 32px;
        align-items: center;
        border-bottom: 1px solid rgba(@black-0,.1);

        &Goods {
            align-items: center;
        }

        .OrdersTable-item, LeftoverTable-item {
            font-size: 12px;
            color: @black-3;

            &Num {
                font-size: 16px;
                font-weight: 700;
                color: @black;
            }
        }
    }

    &-body {
        padding: 20px 32px;
    }

    &-item {
        &Id,
        &IdCategory,
        &Price,
        &Count,
        &Comment {
            padding-right: 5px;
        }
    }

    &-comment {
        font-size: 12px;
        padding: 10px 15px 10px 0;
    }

    &-info {
        display: flex;
        flex-direction: column;
        padding: 10px 0;

        .ButtonDefault,
        .ButtonPrimary {
            height: 40px;
            line-height: 40px;
        }
    }

    &-badge {
        margin-bottom: 18px;
    }
}

.OrdersGoods-row,
.OrdersTable-headGoods,
.LeftoverGoods-row,
.LeftoverTable-headGoods {
    display: grid;
}

.OrdersGoods, .LeftoverGoods {
    &-row {
        padding: 10px 0;
    }

    &-item {
        &Title,
        &Category,
        &Price,
        &Count {
            padding-right: 5px;
        }
    }
}

.LeftoverGoods {
    &-itemName {
        font-size: 14px;
        line-height: 20px;
        color: #27293A;
    }
    &-itemCategory {
        font-size: 12px;
        line-height: 15px;
        color: #525461;
    }
    &-itemCount {
        font-size: 14px;
        line-height: 17px;
        color: #525461;
    }
}

.Badge {
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    font-weight: 500;
    border-radius: @border-radius;
    color: @black;

    &:before {
        content: "";
        display: inline-flex;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
    }

    &-success {
        background: @light-primary;

        &:before {
            background: @main;
        }
    }
}

.ShowToggle {
    font-size: 14px;
    line-height: 20px;
    color: #2D8AF1;
    opacity: 1;
    margin-left: 32px;
    margin-bottom: 28px;
    width: 150px;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.StrokeIcon {
    padding-left: 5px;
}

.BackStroke {
    margin-top: -1px;
}
.BackStroke svg {
    transform: rotate(180deg);
}

.CentreXPosition {
    margin-left: 50%;
    transform: translateX(-50%);
}

.OrdersTable, .LeftoverTable {
    &-comment {
        &-label {
            font-size: 12px;
            line-height: 20px;
            color: #757681 !important;
            opacity: 0.7 !important;
        }
        &-info {
            padding-bottom: 16px;
        }
    }
}

@media screen and (max-width: 991px) {
    .OrdersTable, .LeftoverTable {
        &-head,
        &-body {
            padding-left: 14px;
            padding-right: 14px;
        }

        &-body {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &-item {
            &Sum {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &Num {
                order: 2;
            }
        }

        &-comment {
            padding: 0 0 10px 0;
        }

        &-info {
            align-items: flex-end;
        }
    }

    .OrdersGoods-item, .LeftoverGoods-item {
        margin-top: 8px;

        &Title {
            font-weight: 500;
        }

        &Category,
        &Price,
        &Count {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

@media screen and (min-width: 992px) {
    .LeftoverTable {
        &-contact {
            font-size: 14px;
            line-height: 22px;
            color: #27293A;
        }
        &-itemHeader {
            font-size: 12px;
            line-height: 20px;
            color: #757681;
        }
        &-head,
        &-body {
            grid-template-rows: repeat(1, auto);
            grid-template-columns: minmax(490px, 13fr) minmax(160px, 3fr);
        }
        &-label {
            font-size: 12px;
            line-height: 20px;
            color: #757681;
            opacity: 0.7;
        }
        &-headerTitle {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #27293A;
        }
        &-headerLabel {
            font-size: 12px;
            line-height: 20px;
            color: #757681;
        }
    }
    .OrdersTable {
        &-head,
        &-body {
            grid-template-rows: repeat(1, auto);
            grid-template-columns: minmax(490px, 13fr) minmax(150px, 5fr) minmax(160px, 3fr) minmax(160px, 3fr);
        }
        &-label {

            font-size: 12px;
            line-height: 20px;
            color: #757681;
            opacity: 0.7;
        }
    }

    .OrdersGoods-row,
    .OrdersTable-headGoods {
        grid-template-rows: repeat(1, auto);
        grid-template-columns: minmax(200px, 10fr) minmax(120px, 2fr) minmax(90px, 1fr) minmax(80px, 1fr);
    }
    // Leftovers
    .LeftoverGoods-row,
    .LeftoverTable-headGoods {
        grid-template-rows: repeat(1, auto);
        grid-template-columns: minmax(200px, 10fr) minmax(120px, 2fr) minmax(90px, 1fr);
    }
}

.OrdersGoods-itemPrice, .LeftoverGoods-itemPrice {
    font-weight: 600;
}

.OrdersGoodsItemCategory {
    font-size: 12px!important;
}
