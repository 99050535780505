.AccessPriceEditBackWrapper {
  display: grid;
  width: 90px;
  place-items: center;
  grid-auto-flow: column;
  font-size: 16px;
  line-height: 19px;
  color: #757681;
  grid-template-columns: 14px 47px;
  column-gap: 12px;
  cursor: pointer;
}

.AccessPriceEditWrapper {
  background: #FFFFFF;
  border: 1px solid #EEEEEF;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 32px;
  padding: 40px;
  display: grid;
  grid-auto-flow: row;
}

.AccessPriceEditRadioWrapper {
  margin-top: 32px;
}

.AccessPriceEditAreaWrapper {
  font-size: 14px !important;
  line-height: 160% !important;
  color: #27293A !important;
}


.AccessPriceEditTitle {
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #27293A;
}

.AccessPriceEditHallsArea {
  padding: 16px 0 16px 32px;
}

.SelectHallsWrapper {
  width: calc(100vw - 500px);
}
