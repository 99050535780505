.SearchWrapper {
  display: flex;

  &,
  .ant-select {
    flex: 1 1 0;
  }

  input {
    height: @height-base;
    border-radius: 2px 0 0 2px;
    border-color: @main;
  }

  .ButtonDefault {
    margin-left: -1px;
    border-radius: 0 2px 2px 0;
  }
}

@media screen and (max-width: 991px) {
    .SearchWrapper {
      input {
          height: @height-sm - 2px !important;
      }

      .ButtonDefault {
        padding-left: 22px;
        padding-right: 22px;
      }
    }
}
