.ErrorPageWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.ErrorContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ErrorPageTitle {
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #27293A;
  padding-bottom: 16px;
}

.ErrorPageInfo {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #525461;
  padding-bottom: 36px;
}

.ErrorPageImageWrapper {
  padding-bottom: 62px;
}
