.SelectComponent {
  .ant-select-item {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  &.ant-select-multiple .ant-select-selection-item {
    border-color: #d0dcdd;
    background-color: #d0dcdd;

    &-remove {
      color: @black;

      > .anticon {
        vertical-align: 0.2em;
      }
    }
  }

  &.ant-select-disabled {
    opacity: 0.5;
  }
}

.ant-select-item-option-active, .ant-select-item-option-selected {
  background-color: #EEEEEF !important;
}

.SelectWrapper {
  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    border: solid 1px #DEDFE1;
  }

  .ant-select-selector {
    border-color: #e6e6e6;
  }

  .anticon {
    color: @black-3;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector,
  .ant-select:not(.ant-select-disabled):hover {
    border-color: @main;
    box-shadow: none;
  }

  .ant-select-single {
    &.ant-select-open .ant-select-selection-item {
      color: @black;
    }

    &.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 16px;
    }
  }
}

.ErrorSelectWrapper {
  .ErrorText {
    font-size: 10px;
    color: @action-danger;
    margin-top: 2px;
  }

  .ant-select-selector {
    border-color: @action-danger;
  }
}

.NavbarSelect {
  .ant-select {
    color: @black-2;

    &-focused:not(.ant-select-disabled).ant-select-open .ant-select-selector,
    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: @main-light !important;
      box-shadow: 0 0 0 1px @main-light !important;
    }
  }
}

.ant-select-dropdown {
  margin-top: -4px;
  border: 1px solid #DEDFE1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  .ant-select-item {
    color: @black-2;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 400;
    color: @main;
    background-color: @white;
  }
}

.SelectWrapper .ant-select-open {
  .ant-select-arrow {
    transform: rotate(180deg) !important;
  }
}

