.SearchBar {
    background-color: @white;
    border: 1px solid rgba(@black-0, 0.1);
    box-sizing: border-box;
    padding: 32px;
    border-radius: @border-radius;
    font-size: 16px;

    &-header {
        margin-bottom: 24px;
    }

    &-body {
        display: flex;
    }
}

@media screen and (max-width: 991px) {
    .SearchBar {
        padding: 20px;

        &-body {
            .ant-select-selector {
                height: @height-sm !important;
                align-items: center;
            }
        }
    }
}
