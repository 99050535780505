.DeliveryInfoSidebar {
  border-radius: 2px;
  padding: 16px 20px;
  background: #FFFBE6;
  border: 1px solid #FFE58F;
  box-sizing: border-box;
  position: relative !important;
  margin-bottom: 20px;
  opacity: 0.75;

  div {
    color: #27293a;
    font-family: 'Rubik', sans-serif;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.4em !important;
    letter-spacing: 0em !important;
    text-align: left !important;
  }
}

@media screen and (min-height: 900px) and (min-width: 992px) {
  .DeliveryInfo-sidebar {
    width: 320px !important;
  }
}
