.TobbaccoInfoBlock {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  display: block;
  height: 44px;
  width: 884px;
  border-radius: 8px;
  background: #2d8af1;
  opacity: 0.75;
  margin-top: 12px;
  margin-bottom: 8px;
  margin-left: 32px;
  position: relative;
}

.InfoIcon {
  margin-left: 14px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.TobbaccoInfoContent {
  margin-left: 38px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  width: 684px;
  height: 17px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}
